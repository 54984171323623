import request from '../utils/request';

export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/AdminUser/find',
        method: 'get',
        params: query
    });
};


export const deleteData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/AdminUser/remove',
        method: 'get',
        params: query
    });
};

export const AddData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/AdminUser/add',
        method: 'post',
        data: query
    });
};

export const UpData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/AdminUser/up',
        method: 'post',
        data: query
    });
};

export const RoleRootData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/AdminUser/RoleRootData',
        method: 'get',
        params: query
    });
};


