<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 后台管理员管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="handladd">添加</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Name" label="姓名"></el-table-column>
        <el-table-column prop="Email" label="电子邮箱"></el-table-column>
        <el-table-column prop="Mobile" label="电话号码" width="180" align="center"></el-table-column>
        <el-table-column prop="Mode" label="角色"></el-table-column>
        <el-table-column prop="NewTime" label="创建时间" width="180" align="center"></el-table-column>
        <el-table-column width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                @click="handleEdit( scope.row)"
            >编辑
            </el-button>
            <el-button
                type="text"
                class="red"
                :disabled="scope.row.Mode=='root'"
                @click="handleDelete( scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 修改个人信息-->
    <el-dialog title="操作" v-model="edit.user" width="600px">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="姓名" :required="true">
          <el-input v-model="form.Name"></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱">
          <el-input v-model="form.Email"></el-input>
        </el-form-item>
        <el-form-item label="电话号码" :required="true">
          <el-input v-model="form.Mobile"></el-input>
        </el-form-item>
        <el-form-item label="角色" :required="true">
          <el-select v-model="form.Mode" placeholder="请选择角色" class="handle-select mr10" :disabled="edit.Mode">
            <el-option v-for="(item,key) in CheckboxData" :key="key" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="密码">
          <el-input placeholder="请输入密码" v-model="form.Password" show-password></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="edit.user = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">{{ edit.start }}</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {fetchData, deleteData, AddData, UpData, RoleRootData} from "../../api/ActivityAdmin";


export default {
  data() {
    return {
      query: {
        address: "",
        name: "",
        pageIndex: 1,
        pageSize: 10
      },
      tableData: [],
      CheckboxData: [],
      JionData: [],
      edit: {
        user: false,
        start: '',
        Mode: false,
        Password: false
      },
      pageTotal: 0,
      form: {},

    };
  },
  created() {
    this.getData();
    this.RoleRootFindData()
  },
  methods: {
    RoleRootFindData() {
      RoleRootData().then(res => {
        let sum = res.sum
        let data = []
        for (let item in sum) {
          data.push(sum[item].Name)
        }
        this.CheckboxData = data
      })
    },
    handladd() {
      this.form = {}
      this.edit.user = true;
      this.edit.start = '添加'
      this.edit.Mode = false
    },
    getData() {
      let skip = this.query.pageIndex
      fetchData({skip}).then(res => {
        let bindata = res.sum;
        for (let item in bindata) {
          if (bindata[item].Mode == '超级管理员') {
            bindata[item].disabled = true
          } else {
            bindata[item].disabled = false
          }
        }
        this.pageTotal = res.number
        this.tableData = bindata;
      });
    },
    // 打开编辑
    handleEdit(row) {
      let Mode = row.Mode
      if (Mode == 'root') {
        this.edit.Mode = true
      } else {
        this.edit.Mode = false
      }
      delete row.Password
      this.form = {
        ...row
      };
      this.edit.user = true;
      this.edit.Password = false
      this.edit.start = '更新'
    },
    //删除该信息
    handleDelete(row) {
      let _id = row._id
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        deleteData({_id}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          }
        });

      }).catch(() => {
      });
    },
    // 保存编辑
    saveEdit() {
      let start = this.edit.start
      let form = {...this.form}
      delete form.disabled
      if (form.Name && form.Mobile && form.Mode) {
        if (start == '添加') {
          AddData(form).then(res => {
            if (res.code == 0) {
              this.getData();
              this.edit.user = false;
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
          UpData(form).then(res => {
            if (res.code == 0) {
              this.getData();
              this.edit.user = false;
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          })
        }
      } else {
        this.$message.error('缺少必填项');
      }


    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 160px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
