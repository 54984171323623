<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 用户管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-input v-model="query.name" placeholder="请输入搜索关键词" class="handle-input mr10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
        <el-button type="text" style="margin-left: 30px" @click="ResetSearch">重置</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
      >
        <el-table-column label="头像" width="120">
          <template #default="scope">
            <el-avatar  size="small" :src="scope.row.imgUrl"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="Name" label="笔名" width="180" :required="true"></el-table-column>
        <el-table-column prop="PhoneMobile" label="手机号码" width="190"></el-table-column>
        <el-table-column prop="Collect" label="点赞（收藏）" width="130" align="center"></el-table-column>
        <el-table-column prop="CollectNot" label="不喜欢" width="100" align="center"></el-table-column>
        <el-table-column prop="Popularity" label="人气值" width="100" align="center"></el-table-column>
        <el-table-column prop="Woke" label="作品" width="100" align="center"></el-table-column>
        <el-table-column prop="Newtime" label="加入时间" width="200"></el-table-column>
        <el-table-column prop="Uptime" label="登陆时间" width="230"></el-table-column>
        <el-table-column width="100" align="center" fixed="right">
          <template #default="scope">
            <el-button
                type="text"
                @click="openWork( scope.row._id)"
            >作品
            </el-button>
<!--            <el-button-->
<!--                type="text"-->
<!--                @click="handleEdit( scope.row)"-->
<!--            >编辑-->
<!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
<Work ref="Work"></Work>
  </div>
</template>

<script>
import {fetchData, regexfetchData} from "../../api/User";
import Work from "../../components/Work";

export default {
  data() {
    return {
      query: {
        address: "",
        name: "",
        pageIndex: 1,
        pageSize: 10,
        type: '常规'
      },
      loading: true,
      tableData: [],
      pageTotal: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    openWork(user_id){
      this.$refs.Work.openview(user_id)
    },
    ResetSearch() {
      this.query.pageIndex = 1
      this.query.type = '常规'
      this.query.name = ''
      this.query.address = ''
      this.loading=true
      this.getData()
    },
    handleSearch() {
      let name = this.query.name
      if ( name) {
        this.query.pageIndex = 1
        this.query.type = '模糊'
        this.loading=true
        this.getData()
      } else {
        this.$alert('关键词必填', '系统', {
          confirmButtonText: '确定',
        });
      }
    },

    getData() {
      if (this.query.type == '常规') {
        fetchData({
          skip: this.query.pageIndex
        }).then(res => {
          let bindata = res.sum;
          let number = res.number
          this.pageTotal = number
          this.tableData = bindata;
          this.loading=false
        });
      } else {
        regexfetchData({
          skip: this.query.pageIndex,
          address: this.query.address,
          name: this.query.name,
        }).then(res => {
          let bindata = res.sum;
          let number = res.number
          this.pageTotal = number
          this.tableData = bindata;
          this.loading=false
        });
      }

    },
    // 分页导航
    handlePageChange(val) {
      this.loading=true
      this.query.pageIndex = val
      this.getData();
    }
  },
  components:{
    Work
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
