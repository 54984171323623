<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i>抽奖管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-button @click="run" class="open">开始抽奖</el-button>
      <el-table
          v-if="tableData.length>=1"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="SidNumber" label="绘画坐标"></el-table-column>
        <el-table-column prop="PhoneMobile" label="手机号码"></el-table-column>
        <el-table-column prop="Name" label="姓名	"></el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
import {Lottery} from '../../api/CanvasAdmin'


export default {
  data() {
    return {
      tableData: []
    }
  },
  mounted() {

  },
  components: {},
  methods: {
    run() {
      this.$prompt('请输入数量', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        this.tableData=[]
        const load = this.$loading({
          lock: true,
          text: '正在生成中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        Lottery({number: value}).then(res => {
          load.close()
          if (res.code == 0) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.tableData=res.sum
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        }).catch(() => {
          this.$message({
            message: '参数错误',
            type: 'warning'
          });
        })
      }).catch(() => {
      });

    }
  }
}
</script>

<style scoped lang="scss">

.open{
  margin-bottom: 10px;
}
</style>
