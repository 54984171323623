<template>
  <div>
    <el-dialog v-model="view.add" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false" width="700px" title="添加画布">
      <el-form label-width="120px" class="info">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="画布编号" :required="true">
              <el-input v-model="form.number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="画布名称" :required="true">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="画布负责人" :required="true">
              <el-input v-model="form.canvasRes"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="画布背景图（压缩）" :required="true" class="changeImg">
          <el-image
              class="avatar"
              v-if="form.imageUrl"
              :src="form.imageUrl"
              :preview-src-list="[form.imageUrl]">
          </el-image>
          <el-upload
              class="upload-block"
              accept="image/png,image/jpg,image/jpeg"
              :action='actionUrl'
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-button type="default">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="画布背景图（原图）" :required="true" class="changeImg">
          <el-image
              class="avatar"
              v-if="form.imageUrls"
              :src="form.imageUrls"
              :preview-src-list="[form.imageUrls]">
          </el-image>
          <el-upload
              class="upload-block"
              accept="image/png,image/jpg,image/jpeg"
              :action='actionUrl'
              :show-file-list="false"
              :on-success="handleAvatarSuccesss"
              :before-upload="beforeAvatarUpload">
            <el-button type="default">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-form label-width="80">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="总画布行数" :required="true">
              <el-input v-model="form.canvasNumberX"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总画布列数" :required="true">
              <el-input v-model="form.canvasNumberY"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="创作尺寸(X)" :required="true">
              <el-input v-model="form.creationSizeX"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="创作尺寸(Y)" :required="true">
              <el-input v-model="form.creationSizeY"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form ref="form" label-width="100px" size="mini">
          <el-form-item label="终止日期">
            <input type="datetime-local" v-model="form.timeEnd"/>
          </el-form-item>
        </el-form>
      </el-form>
      <template #footer>
    <span class="dialog-footer">
           <el-button @click="closeView">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">添加</el-button>
    </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {Actions, initData} from '../../api/CanvasAdmin'

export default {
  mounted() {
    this.actionUrl = Actions()
  },
  data() {
    return {
      actionUrl: '',
      view: {
        add: false
      },
      form: {
        number: '',
        imageUrl: '',
        title: '',
        canvasRes: '',
        canvasNumberX: '',
        canvasNumberY: '',
        creationSizeX: '',
        creationSizeY: '',
        timeEnd: ''
      }
    }
  },
  methods: {
    setTime() {
      let Data = this.form.timeEnd
      let timeYear = new Date(Data).getFullYear(),//年
          timeMonth = new Date(Data).getMonth() + 1,//月份
          timeDate = new Date(Data).getDate(),//日
          timeHours = new Date(Data).getHours(),//时
          timeMinutes = new Date(Data).getMinutes();  //分
      if (timeMonth < 10) {
        timeMonth = '0' + timeMonth
      }
      if (timeDate < 10) {
        timeDate = '0' + timeDate
      }
      if (timeHours < 10) {
        timeHours = '0' + timeHours
      }
      if (timeMinutes < 10) {
        timeMinutes = '0' + timeMinutes
      }
      return timeYear + '/' + timeMonth + '/' + timeDate + ' ' + timeHours + ':' + timeMinutes + ':00';
    },
    saveEdit() {
      let form = JSON.parse(JSON.stringify(this.form))
      for (let item in form) {
        let text = form[item]
        if (text == '') {
          this.$message.warning('缺少必填项')
          return;
        }
      }
      let canvasNumberX = form.canvasNumberX
      let canvasNumberY = form.canvasNumberY
      let creationSizeX = form.creationSizeX
      let creationSizeY = form.creationSizeY
      let timeEnd = this.setTime()
      form.timeEnd = timeEnd
      form.canvasNumberX = Number(canvasNumberX)
      form.canvasNumberY = Number(canvasNumberY)
      form.creationSizeX = Number(creationSizeX)
      form.creationSizeY = Number(creationSizeY)
      if (isNaN(form.canvasNumberX) || isNaN(form.canvasNumberY) || isNaN(form.creationSizeX) || isNaN(form.creationSizeY)) {
        this.$message.warning('参数不合法')
        return;
      }

      initData(form).then(res => {
        if (res.code == 0) {
          this.$parent.getdata()
          this.$message.success(res.message)
          let form = JSON.parse(JSON.stringify(this.form))
          for (let item in form) {
            form[item] = ''
          }
          this.form = form
          this.view.add = false
        } else {
          this.$message.warning(res.message)
        }
      })

      // this.$message.success('提交成功')
      // if (isNaN (text) ) {
      //   this.$message.warning('参数不合法')
      // }

    },
    handleAvatarSuccess(res) {
      this.form.imageUrl = res.imgfile;
    },
    handleAvatarSuccesss(res) {
      this.form.imageUrls = res.imgfile;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 50;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 50MB!');
      }
      return isJPG && isLt2M;
    },
    closeView() {
      this.view.add = false
    },
    openAdd() {
      this.view.add = true
    }
  }
}
</script>

<style scoped lang="scss">
.info {
  margin-bottom: 30px;
}

.changeImg .avatar-uploader .el-upload {
  border-radius: 50%;
}

.upload-block {
  ::v-deep .el-upload--text {
    width: auto;
    height: auto;
    border: none;
  }
}

.avatar {
  width: 300px;
}
</style>
