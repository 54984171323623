<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i>画布视图
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-row :gutter="20" class="handle-box">
        <el-col :span="8">
          <el-card shadow="hover" :body-style="{ padding: '0px' }">
            <div class="grid-content grid-con">
              <i class="el-icon-menu grid-con-icon">名称</i>
              <div class="grid-cont-right">
                <div class="grid-num">{{ openCanvasData.title }}</div>
                <div></div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover" :body-style="{ padding: '0px' }">
            <div class="grid-content grid-con">
              <i class="el-icon-check grid-con-icon">创作作品</i>
              <div class="grid-cont-right">
                <div class="grid-num">{{ openCanvasData.creationNumber }}</div>
                <div></div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover" :body-style="{ padding: '0px' }">
            <div class="grid-content grid-con">
              <i class="el-icon-time grid-con-icon">最后提交时间</i>
              <div class="grid-cont-right">
                <div class="grid-num">
                  <span v-if=" openCanvasData.upTime">{{ openCanvasData.upTime }}</span>
                  <span v-else>暂无人创作</span>
                </div>
                <div></div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-folder"></i>画布管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button icon="el-icon-plus" @click="openAdd">添加画布</el-button>
      </div>
      <el-table
          :data="tableData"
      >
        <el-table-column prop="title" label="名称" width="180"></el-table-column>
        <el-table-column prop="bgImg" label="画布背景图" width="180">
          <template #default="scope">
            <el-image
                class="avatar"
                :src="scope.row.bgImg"
                :preview-src-list="[scope.row.bgImg]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="newTime" label="添加日期" width="180"></el-table-column>
        <el-table-column prop="creationNumber" width="180" label="创作数量"></el-table-column>
        <el-table-column prop="creationSize" width="180" label="创作尺寸"></el-table-column>
        <el-table-column prop="canvasSize" width="180" label="画布背景尺寸"></el-table-column>
        <el-table-column prop="canvasNumberX" width="180" label="总画布行数"></el-table-column>
        <el-table-column prop="canvasNumberY" width="180" label="总画布列数"></el-table-column>
        <el-table-column prop="canvasRes" width="100" label="画布负责人"></el-table-column>
        <el-table-column prop="upTime" width="180" label="最后提交时间">
          <template #default="scope">
            <span v-if="scope.row.upTime">{{ scope.row.upTime }}</span>
            <span v-else>暂无人创作</span>
          </template>
        </el-table-column>
        <el-table-column prop="claim" width="180" label="画布认领数量"></el-table-column>
        <el-table-column prop="start" width="100" label="设置状态" fixed="right">
          <template #default="scope">
            <span v-if="scope.row.start"><el-tag type="success">已开启</el-tag></span>
            <span v-else><el-tag type="warning">未开启</el-tag></span>
          </template>
        </el-table-column>

        <el-table-column prop="start" width="150">
          <template #default="scope">
            <el-button type="success" plain  @click="opencanvas(scope.row._id)"  v-if="!scope.row.start">启用</el-button>
          </template>
        </el-table-column>

        <el-table-column prop="start" width="150">
          <template #default="scope">
            <el-button type="danger" plain @click="removedata(scope.row._id)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="start" width="150">
          <template #default="scope">
            <el-button   @click="openGenerate(scope.row.titleNumber)">设置生成图</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="start" width="150">
          <template #default="scope">
            <el-button plain @click="openEffect(scope.row.titleNumber)">生成预览图（高清）</el-button>
          </template>
        </el-table-column>

        <el-table-column prop="start" width="150">
          <template #default="scope">
            <el-button plain @click="openEffects(scope.row.titleNumber)">生成预览图（压缩）</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="start" width="150">
          <template #default="scope">
            <el-button plain @click="openOnchain(scope.row.titleNumber)">区块链</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230" fixed="right">
          <template #default="scope">
            <el-button plain @click="openlist(scope.row.titleNumber)">创作数据</el-button>
            <el-button plain @click="openTime(scope.row.titleNumber,scope.row.timeEnd)">时间管理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <adminTime ref="adminTime"></adminTime>
    <addCanvas ref="addCanvas"></addCanvas>
    <CanvasMap ref="CanvasMap"></CanvasMap>
    <CanvasList ref="CanvasList"></CanvasList>
    <CanvasEffect ref="CanvasEffect"></CanvasEffect>
    <Chain ref="Chain"></Chain>
    <Generate ref="Generate"></Generate>
  </div>
</template>

<script>
import {fetchData, deleteData, openCanvas} from '../../api/CanvasAdmin'
import addCanvas from './addCanvas'
import CanvasMap from './CanvasMap'
import CanvasList from './CanvasList'
import adminTime from './adminTime'
import CanvasEffect from './CanvasEffect'
import Chain from './Chain'
import Generate from './Generate'


export default {
  data() {
    return {
      openCanvasData: '',
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0
      },
      visit: {
        title: '',
        claim: '',
        upTime: ''
      },
      tableData: [
        {title: '22'}
      ],
    }
  },
  mounted() {
    this.getdata()
  },
  components: {
    addCanvas,
    CanvasMap,
    CanvasList,
    adminTime,
    CanvasEffect,
    Chain,
    Generate
  },
  methods: {
    openGenerate(titleNumber){
      this.$refs.Generate.open(titleNumber)
    },
    openOnchain(titleNumber){
      this.$refs.Chain.open(titleNumber)
    },
    openEffect(titleNumber){
      this.$refs.CanvasEffect.openView(titleNumber)
    },
    openEffects(titleNumber){
      this.$refs.CanvasEffect.openViews(titleNumber)
    },
    opencanvas(_id) {
      this.$confirm("确定要启用该画布吗？", "提示", {
        type: "warning",
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        openCanvas({_id}).then(res => {
          if (res.code == 0) {
            this.getdata()
            this.$message.success(res.message)
          } else {
            this.$message.warning(res.message)
          }
        })
      }).catch()
    },
    openAdd() {
      this.$refs.addCanvas.openAdd()
    },
    openTime(titleNumber,timeEnd){
      this.$refs.adminTime.openTime(titleNumber,timeEnd)
    },
    openlist(titleNumber) {
      this.$refs.CanvasList.openlist(titleNumber)

    },
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    removedata(id) {
      this.$confirm("确定要删除该画布吗？", "提示", {
        type: "warning",
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        deleteData(id).then(res => {
          if (res.code == 0) {
            this.getdata()
            this.$message.success(res.message)
          } else {
            this.$message.warning(res.message)
          }
        })
      }).catch()

    },
    getdata() {
      let skip = this.query.pageIndex
      fetchData({skip}).then(res => {
        if (res.code == 0) {
          this.openCanvasData = res.openCanvas
          this.tableData = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.avatar {
  width: 100px;
  height: 50px;
  border-radius: 6px;
}

.handle-box {
  margin-bottom: 10px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 40px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 15px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 15px;
  width: 130px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;

}

.grid-con .grid-con-icon {
  background: rgb(45, 140, 240);
}
</style>
