<template>
  <div class="sidebar">
    <el-menu
        default-active="/dashboard"
        class="sidebar-el-menu"
        background-color="#f5f5f5"
        text-color="#000"
        active-text-color="#ff725e"
        unique-opened
        router
    >
      <template v-for="item in RoleRouter">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                  v-if="subItem.subs"
                  :index="subItem.index"
                  :key="subItem.index"
              >
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                    v-for="(threeItem, i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index"
                >{{ threeItem.title }}
                </el-menu-item>
              </el-submenu>
              <el-menu-item
                  v-else
                  :index="subItem.index"
                  :key="subItem.index"
              >{{ subItem.title }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>

import {SiderData, roleData} from "../api/Sidebar";

export default {
  data() {
    return {
      RoleRouter: [],
      items: [{
        index: "/dashboard",
        title: "首页"
        },
        {
          index: "/User/index",
          title: "用户管理"
        },
        {
          index: "/CanvasAdmin/index",
          title: "画布管理"
        },
        {
          index: "/ActivityAdmin/Admin",
          title: "后台管理员管理"
        },
        {
          index: "/ActivityAdmin/PermissionRouter",
          title: "资源管理"
        },
        {
          index: "/ActivityAdmin/RoleRoot",
          title: "角色管理"
        },
        {
          index: "/SensitiveAdmin/index",
          title: "敏感词管理"
        },
      ]
    }
  },
  created() {
    let UserInfo = this.$store.state.UserInfo

    let Name = UserInfo.Mode
    if (Name){
      this.getrole()
    }
  },

  methods: {
    getrole() {
      let UserInfo = this.$store.state.UserInfo
      let Name = UserInfo.Mode
      roleData({Name}).then(res => {
        let RoleRouter = res.RoleRouter
        let f = []
        for (let item in RoleRouter) {
          let s = RoleRouter[item].path
          if (RoleRouter[item].subs){
            if (RoleRouter[item].remarks == '操作和功能') {

              f.push({
                index: s,
                title: RoleRouter[item].label
              })
            }else {
              let children=RoleRouter[item].children
              let sub=[]
              for (let item in children){
                if (children[item].subs){
                  sub.push({
                    index: children[item].path,
                    title: children[item].label
                  })
                }
              }
              f.push({
                index: s,
                title: RoleRouter[item].label,
                subs:sub
              })
            }
          }
        }
        this.RoleRouter = f
      })
    },
    UserInfoTitle() {
      let UserInfo = this.$store.state.UserInfo
      let Mode = UserInfo.Mode
      SiderData({
        Title: Mode
      }).then(res => {
        let items = res.sum.checkList
        if (items == undefined) {
          this.UserInfoTitle()
        }
        items = items.sort((a, b) => a.Order - b.Order)
        for (let item in items) {
          delete items[item].Order
          delete items[item].remarks
          items[item].index = items[item].path
        }
        this.items = items
      });
    }
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar > ul {
  height: 100%;
}


</style>
