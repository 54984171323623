import request from '../utils/request';

export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'admin/AccountSetup/login',
        method: 'post',
        data: query
    });
};

