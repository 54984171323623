import request from '../utils/request';



export const add = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/SensitiveAdmin/add',
        method: 'post',
        data: query
    });
};

export const find = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/SensitiveAdmin/find',
        method: 'get',
        params: query
    });
};

export const remove = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/SensitiveAdmin/remove',
        method: 'get',
        params: query
    });
};

