<template>
  <div>
    <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false"
               width="1000px" title=" 画布预览图">
      <div class="main" id="main">
        <canvas ref="canvas" id="imageWrapper" style="width: 100%;height: 100%"></canvas>
        <img :src="imgsum" id="imgsum"/>
      </div>
      <template #footer>
    <span class="dialog-footer">
         <el-button @click="downloadImg">下载</el-button>
           <el-button @click="view=false">关闭</el-button>
    </span>
      </template>
    </el-dialog>
    <Load ref="Load"></Load>
  </div>
</template>

<script>
import Load from "../../components/load";
import {listTol, base64Img} from "../../api/CanvasAdmin";
// import html2canvas from 'html2canvas'

export default {
  data() {
    return {
      view: false,
      titleNumber: '',
      width: 0,
      height: 0,
      data: [],
      creationSizeX: 0,
      creationSizeY: 0,
      imgsum: '',
      zoom: 1
    }
  },
  mounted() {

  },
  components: {
    Load
  },
  methods: {
    openViews(titleNumber) {
      this.zoom = 27
      this.titleNumber = titleNumber
      this.view = true
      setTimeout(() => {
        this.getData()
      }, 100)
    },
    async downloadImg() {
      // this.$refs.Load.open(1)
      setTimeout(() => {
        let canvas = this.$refs.canvas
        const imgBase64 = canvas.toDataURL("image/png")
        let url = imgBase64;                            // 获取图片地址
        let a = document.createElement('a');          // 创建一个a节点插入的document
        let event = new MouseEvent('click')           // 模拟鼠标click点击事件
        a.download = 'beautifulGirl'                  // 设置a节点的download属性值
        a.href = url;                                 // 将图片赋值给a节点的
        a.dispatchEvent(event)
        this.$refs.Load.close()
      }, 500)
    },
    openView(titleNumber) {
      this.zoom = 5
      this.titleNumber = titleNumber
      this.view = true
      setTimeout(() => {
        this.getData()
      }, 100)
    },
    getData() {
      let titleNumber = this.titleNumber
      let canvas = this.$refs.canvas
      let crx = canvas.getContext('2d')
      listTol({titleNumber}).then(res => {
        let data = res.data
        let CanvasTotal = res.CanvasTotal
        this.creationSizeX = CanvasTotal.creationSizeX/this.zoom
        this.creationSizeY = CanvasTotal.creationSizeY/this.zoom

        let bgImgs = CanvasTotal.bgImg
        let canvasSizeX = (CanvasTotal.canvasNumberX * CanvasTotal.creationSizeX)/this.zoom
        let canvasSizeY = (CanvasTotal.canvasNumberY * CanvasTotal.creationSizeY)/this.zoom

        canvas.width = canvasSizeX
        canvas.height = canvasSizeY

        this.data = data


        base64Img({url: bgImgs}).then(res => {
          let img = new Image()
          img.src = res
          img.onload = () => {
            crx.drawImage(img, 0, 0, canvasSizeX, canvasSizeY)
          }
          this.initCanvas()
        })
      })
    },
    base64Imgs(i) {
      return new Promise((resolve) => {
        const {data} = this
        let imgdata = data[i]
        let bgImg = imgdata.imageUrl
        let canvas = this.$refs.canvas
        let crx = canvas.getContext('2d')
        let num = JSON.parse(JSON.stringify(this.data.length))
        let numss = JSON.parse(JSON.stringify(i))
        this.$refs.Load.texts(`${numss}/${num}`)
        if (imgdata.sinx !== 'undefined' || imgdata.siny !== 'undefined') {
          let sinx = Number(imgdata.sinx)/this.zoom
          let siny = Number(imgdata.siny)/this.zoom
          base64Img({url: bgImg}).then(res => {
            let img = new Image()
            img.src = res
            img.onload = () => {
              crx.drawImage(img, sinx, siny, this.creationSizeX, this.creationSizeY)
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    },
    async initCanvas() {
      // this.$refs.Load.open()
      const {data} = this
      // let num = 0
      for (let i in data) {
        await this.base64Imgs(i)
      }
      this.$refs.Load.close()
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  width: 900px;
  height: 500px;
  margin: 0 auto;

}
</style>
