<template>
  <div>
    <el-dialog v-model="view.work" :close-on-click-modal="false" :close-on-press-escape="false" width="900px">
      <el-table
          v-loading="loading"
          :data="tableData"
          height="400"
      >
        <el-table-column prop="titleNumber" label="活动编号" width="80"></el-table-column>
        <el-table-column prop="SidNumber" label="作品编号" width="120"></el-table-column>

        <el-table-column prop="imageUrl" label="创作预览图" width="100">
          <template #default="scope">
            <el-image
                style=" height: 50px"
                :src="scope.row.imageUrl"
                :preview-src-list="[scope.row.imageUrl]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="创作时间" width="180"></el-table-column>
        <el-table-column prop="chainevId" label="存证编号" width="200"></el-table-column>
        <el-table-column prop="chainHash" label="哈希值" width="200"></el-table-column>
        <el-table-column prop="chainNumber" label="互联法编号" width="200"></el-table-column>
        <el-table-column prop="collect" label="点赞" width="100"></el-table-column>
        <el-table-column prop="collectNot" label="点踩" width="100"></el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import {fetchUserData} from '../api/User'

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      view: {
        work: false
      }
    }
  },
  methods: {
    openview(user_id) {
      this.tableData = []
      this.loading = true
      this.view.work = true
      fetchUserData({user_id}).then(res => {
        let sum = res.sum
        this.tableData = sum
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
