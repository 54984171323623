import request from '../utils/request';



export const Homeinit = query => {
    return request({
        url: process.env.VUE_APP_URL+'admin/dashboard/init',
        method: 'get',
        params: query
    });
};

