<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 敏感词管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-plus" @click="handladd">添加</el-button>
      </div>
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Content" label="敏感词文本"></el-table-column>
        <el-table-column prop="NewTime" label="新增时间"></el-table-column>
        <el-table-column prop="OperPeople" label="操作人员	"></el-table-column>
        <el-table-column width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                class="red"
                @click="handleDelete(scope.row.id)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 新增信息-->
    <el-dialog title="新增敏感词" v-model="edit" width="600px">
      <el-form ref="form" label-width="100px">
        <el-form-item label="敏感词文本" :required="true">
          <el-input v-model="form.Content"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="close">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {add, find,remove} from '../../api/SensitiveAdmin'

export default {
  data() {
    return {
      tableData: [],
      edit: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0
      },
      form: {
        Content: ''
      },
    };
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let skip = this.query.pageIndex
      find({skip}).then(res => {
        if (res.code == 0) {
          this.tableData = res.data
          this.query.pageTotal = res.number
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    close() {
      this.form.Content = ''
      this.edit = false
    },
    handleDelete(id) {
      this.$confirm("是否确认删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        remove({id}).then(res=>{
          if (res.code == 0) {
            this.$message.success(res.message)
            this.getData();
          } else {
            this.$message.warning(res.message)
          }
        })
      }).catch(() => {
        this.$message.error('删除失败');
      })
    },
    handladd() {
      this.edit = true
    },
    saveEdit() {
      let OperPeople = this.$store.state.UserInfo.Name
      let Content = this.form.Content
      if (Content) {
        add({OperPeople, Content}).then(res => {
          if (res.code == 0) {
            this.$message.success(res.message)
            this.close()
            this.getData();
          } else {
            this.$message.warning(res.message)
          }
        })
      } else {
        this.$message.warning('敏感词为空')
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 160px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
