<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 弹幕管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
      >
        <el-table-column prop="titleNumber" label="活动编号" :required="true"></el-table-column>
        <el-table-column prop="comment" label="内容" width="290"></el-table-column>
        <el-table-column prop="user_id" label="笔名ID" width="250" align="center"></el-table-column>
        <el-table-column prop="user_name" label="笔名" width="180" align="center"></el-table-column>
        <el-table-column prop="times" label="发表时间" width="200" align="center"></el-table-column>
        <el-table-column label="状态" width="120" align="center">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.start">显示</el-tag>
            <el-tag type="danger" v-else>不显示</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="180" align="center" fixed="right">
          <template #default="scope">
            <el-button
                type="text"
                class="green"
                v-if=" !scope.row.start"
                @click="setoperate( scope.row._id)"
            >显示
            </el-button>
            <el-button
                type="text"
                class="yellow"
                v-else
                @click="setoperate( scope.row._id)"
            >不显示
            </el-button>

            <el-button
                type="text"
                class="red"
                @click="rmdata( scope.row._id)"
            >删除
            </el-button>
            <!--            <el-button-->
            <!--                type="text"-->
            <!--                @click="handleEdit( scope.row)"-->
            <!--            >编辑-->
            <!--            </el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <Work ref="Work"></Work>
  </div>
</template>

<script>
import {fetchData, operate,remove} from "../../api/CommentAdmin";
import Work from "../../components/Work";

export default {
  data() {
    return {
      query: {
        address: "",
        name: "",
        pageIndex: 1,
        pageSize: 10,
        type: '常规'
      },
      loading: true,
      tableData: [],
      pageTotal: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    rmdata(_id){
      this.$confirm("确定要删除该数据吗？", "提示", {
        type: "warning",
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        remove({_id}).then(res => {
          if (res.code == 0) {
            this.getData();
            this.$message.success(res.message)
          } else {
            this.$message.warning(res.message)
          }
        })
      })
    },
    setoperate(_id) {
      this.$confirm("确定要执行该操作吗？", "提示", {
        type: "warning",
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        operate({_id}).then(res => {
          if (res.code == 0) {
            this.getData();
            this.$message.success(res.message)
          } else {
            this.$message.warning(res.message)
          }
        })
      })
    },
    openWork(user_id) {
      this.$refs.Work.openview(user_id)
    },

    getData() {
      if (this.query.type == '常规') {
        fetchData({
          skip: this.query.pageIndex
        }).then(res => {
          let bindata = res.sum;
          let number = res.number
          this.pageTotal = number
          this.tableData = bindata;
          this.loading = false
        });
      }

    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.getData();
    }
  },
  components: {
    Work
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.green {
  color: #00a854;
}

.yellow {
  color: rgba(196, 154, 12, 0.81);
}
</style>
