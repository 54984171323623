<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 资源管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <i class="el-icon-circle-plus-outline" @click="handlAdd"></i>
      </div>
      <div class="block">
        <el-tree
            class="tree"
            :data="data"
            node-key="id"
            draggable
            default-expand-all
            :expand-on-click-node="false"
            :render-content="renderContent">
        </el-tree>
      </div>
      <div class="up">
        <p>更新时间:{{ UpTime }}</p>
        <el-button type="primary" size="small" @click="save">保存</el-button>
      </div>
    </div>

    <el-dialog title="资源信息" v-model="view.edit" width="700px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="资源描述" :required="true">
          <el-input v-model="form.label"></el-input>
        </el-form-item>
        <el-form-item label="资源类型" :required="true">
          <el-select v-model="form.remarks" placeholder="请选择">
            <el-option label="基础菜单" value="基础菜单"></el-option>
            <el-option label="操作和功能" value="操作和功能"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资源地址" :required="true">
          <el-input v-model="form.path">
            <template v-slot:prepend>{{ form.paths }}</template>
          </el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="form.sortId" :min="1" :max="10" label="描述文字"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="reset">重置</el-button>
                    <el-button type="primary" @click="saveEdit">确定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>

import {fetchData, SaveData} from "../../api/PermissionRouter";

export default {
  data() {
    return {
      data: [],
      tableData: [],
      form: {},
      forms: [],
      UpTime: '',
      view: {
        start: '',
        edit: false
      }
    };
  },
  created() {
    this.Getdata()
  },
  methods: {
    Getdata() {
      fetchData().then(res => {
        let sum = res.sum
        if (sum) {
          let UpTime = sum.UpTime
          this.UpTime = UpTime
          let data = sum.data
          this.data = data
        }
      })
    },
    save() {
      let data = this.data
      SaveData(data).then(res => {
        this.$message.success(res.message);
        this.Getdata()
      })
    },
    renderContent(h, {node, data}) {
      return h("span", {
        class: "custom-tree-node"
      }, h("span", null, node.label), h("span", null, h("i", {
        class: 'el-icon-circle-plus-outline',
        style: " color: #2d8cf0;cursor: pointer;margin-left: 5px;",
        onClick: () => this.appadd(data)
      }), h("i", {
        class: 'el-icon-delete',
        style: " color: #2d8cf0;cursor: pointer;margin-left: 5px;",
        onClick: () => this.remove(node, data)
      })));
    },
    append(data) {
      const newChild = {label: 'testtest', children: []};
      data.children.push(newChild);
    },
    appadd(data) {
      this.view.start = '添加子菜单'
      this.view.edit = true
      this.form = {}
      this.form.paths = data.path + '/'
      this.form.path = ''
      this.form.sortId = 1
      this.forms = data
    },
    remove(node, data) {
      this.$confirm("是否确认删除吗？", "提示", {
        type: "warning"
      }).then(() => {
        const parent = node.parent;
        const children = parent.data.children || parent.data;
        const index = children.findIndex(d => d.path === data.path);
        children.splice(index, 1);
        this.$message.success('删除成功');
      }).catch(() => {
        this.$message.error('删除失败');
      })

    },
    reset() {
      this.form.path = ''
      this.form.label = ''
      this.form.remarks = ''
      this.form.sortId = 1
    },
    saveEdit() {
      let start = this.view.start
      if (start == '添加主菜单') {
        let form = this.form
        let label = form.label
        let path = '/' + form.path
        let remarks = form.remarks
        let sortId = form.sortId
        if (label && path && remarks) {
          const newChild = {label, children: [], path, remarks, sortId, subs: true,};
          let data = this.data
          data.push(newChild)
          this.data = data
          this.view.edit = false
          this.$message.success('添加成功');
        } else {
          this.$message.error('缺少必填项');
        }
      } else if (start == '添加子菜单') {
        let form = this.form
        let label = form.label
        let path = form.path
        let remarks = form.remarks
        let sortId = form.sortId

        if (label && path && remarks) {
          let data = this.forms
          form.path = form.paths + form.path
          delete form.paths
          const newChild = {label, children: [], path: form.path, remarks, sortId, subs: true,};
          data.children.push(newChild);
          this.view.edit = false
          this.$message.success('添加成功');
        } else {
          this.$message.error('缺少必填项');
        }

      }
    },
    handlAdd() {
      this.view.start = '添加主菜单'
      this.view.edit = true
      this.form = {}
      this.form.sortId = 1
      this.form.paths = '/'
    },
  }

};
</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.el-icon-circle-plus-outline {
  color: #2d8cf0;
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.tree {
  font-size: 13px;
}

.icon-s {
  color: #2d8cf0;
  cursor: pointer;
  margin-left: 5px;
}

.up {
  margin-top: 30px;

  p {
    font-size: 13px;
    margin-bottom: 10px;
  }
}
</style>
